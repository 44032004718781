import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from '../reducers'

import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from '@redux-devtools/extension'

import api from '../middleware/api.middleware'
import download from '../middleware/download.middleware'
import history from '../utils/history'

const migrations = {
  314: (state) => {
    return {}
  }
}

const persistConfig = {
  key: 'root',
  whitelist: ['dashboard', 'widget', 'estimates'],
  storage,
  version: 314,
  migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middleware = routerMiddleware(history)

/*
* delay render until store is rehydrated
*/
export default () => {
  return new Promise((resolve, reject) => {
    try {
      const store = createStore(persistedReducer, composeWithDevTools( applyMiddleware(
        thunk,
        api,
        download,
        middleware
        //, createLogger()
      )))
      
      persistStore(
        store,
        {},
        () => resolve(store)
      )
    } catch (e) {
      reject(e)
    }
  })
}
